<template>
  <v-container @dragover.prevent @drop.prevent>
    <input
      ref="input"
      type="file"
      name="image"
      accept="image/*, video/mp4"
      @change="selectImage"
    />
    <v-row justify="center">
      <v-col cols="12">
        <div v-if="mediaType === 'image'">
          <template v-if="imgSrc">
            <section
              class="cropper-area"
              @dragleave="fileDragOut"
              @dragover="fileDragIn"
              @drop="handleFileDrop"
            >
              <vue-cropper
                ref="cropper"
                :aspect-ratio="ratio"
                :src="imgSrc"
                :class="circle ? 'circle' : ''"
                preview=".preview"
              />
            </section>
          </template>
          <template v-else>
            <div
              class="no-image"
              @dragleave="fileDragOut"
              @dragover="fileDragIn"
              @drop="handleFileDrop"
              v-bind:style="{ 'background-color': dropzoneColor }"
            >
              <v-icon color="#E0E0E0" size="60">
                {{ uploadType == "user" ? "mdi-account-circle" : "mdi-image" }}
              </v-icon>
              <h4>Upload/Drop Photo</h4>
            </div>
          </template>
        </div>
        <div v-else>
          <video :src="imgSrc" autoplay class="w-100"></video>
        </div>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12">
        <v-btn
          x-small
          text
          color="success"
          class="mx-2"
          @click.prevent="showFileChooser"
        >
          <v-icon>mdi-folder-open-outline</v-icon>
        </v-btn>
        <template v-if="mediaType === 'image' && imgSrc">
          <v-btn x-small text color="success" @click.prevent="zoom(0.2)">
            <v-icon>mdi-magnify-plus</v-icon>
          </v-btn>
          <v-btn small text color="success" @click.prevent="zoom(-0.2)">
            <v-icon>mdi-magnify-minus</v-icon>
          </v-btn>
          <v-btn small text color="success" @click.prevent="move(-10, 0)">
            <v-icon>mdi-arrow-left-bold-box</v-icon>
          </v-btn>
          <v-btn x-small text color="success" @click.prevent="move(10, 0)">
            <v-icon>mdi-arrow-right-bold-box</v-icon>
          </v-btn>
          <v-btn x-small text color="success" @click.prevent="move(0, -10)">
            <v-icon>mdi-arrow-up-bold-box</v-icon>
          </v-btn>
          <v-btn x-small text color="success" @click.prevent="move(0, 10)">
            <v-icon>mdi-arrow-down-bold-box</v-icon>
          </v-btn>
          <v-btn x-small text color="success" @click.prevent="cropImage">
            <v-icon>mdi-crop</v-icon>
          </v-btn>
          <v-btn x-small text color="success" @click.prevent="reset">
            <v-icon>mdi-restart</v-icon>
          </v-btn>
        </template>
        <v-btn
          small
          fab
          dark
          color="red"
          v-if="imgSrc || mediaType === 'video'"
          class="mr-2"
          @click.prevent="upload"
        >
          <v-icon>mdi-cloud-upload</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="mediaType === 'image' && imgSrc">
      <p class="mb-1">
        <span v-if="!cropImg" class="caption"
          >Please crop first then upload
          <v-icon small color="red">mdi-cloud-upload</v-icon></span
        >
      </p>
    </v-row>
    <v-row justify="center" v-if="mediaType === 'image' && imgSrc">
      <v-col cols="12" md="6">
        <div
          :class="
            'preview ' + (ratio === 16 / 9 ? 'preview_16_9' : 'preview_1_1')
          "
        />
      </v-col>
      <v-col cols="12" md="6">
        <div class="cropped-image">
          <img v-if="cropImg" :src="cropImg" alt="Cropped Image" />
          <div
            v-else
            :class="
              'caption crop_placeholder ' +
              (ratio === 16 / 9 ? 'cp_16_9' : 'cp_1_1')
            "
          >
            Cropped Image
          </div>
        </div>
      </v-col>
      <v-col cols="12" v-if="hasDescription">
        <v-text-field
          label="Photo Description"
          v-model="description"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  name: "media-uploader",
  components: {
    VueCropper,
  },
  props: {
    uploadType: {
      type: String,
      default: "media", // avatar: user picture, media: dish photo or video
    },
    hasDescription: {
      type: Boolean,
      default: false,
    },
    ratio: {
      type: Number,
      default: 16 / 9,
    },

    imageOnly: {
      type: Boolean,
      default: false,
    },
    circle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      file: null,
      mediaType: "image", // else video
      imgSrc: null,
      cropImg: null,
      data: null,
      description: "",
      dropzoneColor: "#888",
    };
  },
  methods: {
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper
        .getCroppedCanvas({
          maxWidth: 1200,
          maxHeight: 1200,
        })
        .toDataURL(this.file.type);
      console.log("file size will be: ", this.cropImg.length);
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    setData() {
      if (!this.data) return;
      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    getMediaType(file) {
      return file.type.includes("image/") ? "image" : "video";
    },
    selectImage(e) {
      this.file = e.target.files[0];
      if (this.file) {
        this.setImage();
      }
    },
    setImage() {
      if (this.imageOnly) {
        if (!this.file.type.includes("image/")) {
          alert("Please select an image file.");
          return;
        }
      }
      if (
        !this.file.type.includes("image/") &&
        !this.file.type.includes("video/")
      ) {
        alert("Please select an image or video(mp4) file.");
        return;
      }
      this.mediaType = this.getMediaType(this.file);
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          if (this.mediaType === "image") {
            // rebuild cropperjs with the updated source
            if (this.$refs.cropper) {
              this.$refs.cropper.replace(event.target.result);
              // this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
            }
          }
        };
        reader.readAsDataURL(this.file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
    upload: function () {
      this.$emit(
        "uploadMedia",
        this.file,
        this.mediaType === "image" ? this.cropImg : this.imgSrc,
        this.description || ""
      );
    },
    handleFileDrop(e) {
      this.file = e.dataTransfer.files[0];

      if (!this.file) return;
      this.setImage();
    },
    fileDragIn() {
      this.dropzoneColor = "white";
    },
    fileDragOut() {
      this.dropzoneColor = "#eee";
    },
  },
};
</script>
<style>
input[type="file"] {
  display: none;
}

.no-image {
  width: 420px;
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cropper-area {
  max-width: 420px;
}
.preview {
  width: 200px;
  overflow: hidden;
  background: #ccc;
  padding: 8px;
  border: 1px dashed #aaa;
  border-radius: 10px;
}
.preview_16_9 {
  height: calc(200px * (9 / 16));
}

.preview_1_1 {
  height: 200px;
}

.cropped-image {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
}

.crop_placeholder {
  width: 200px;
  background: #ccc;
  padding: 8px;
  border: 1px dashed #aaa;
}

.cp_16_9 {
  height: calc(200px * (9 / 16));
}

.cp_1_1 {
  height: 200px;
}

.cropped-image img {
  max-width: 100%;
  height: 200px;
  border: 1px dashed #aaa;
  border-radius: 10px;
}

.circle .cropper-container .cropper-view-box {
  border-radius: 50%;
}

/*
  .circle  .cropper-container  .cropper-view-box {
    box-shadow: 0 0 0 1px #39f;
    outline: 0;
  }*/
</style>
